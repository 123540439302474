<template>
  <div class="w-full bg-white h-full">
    <div
      class="max-w-md mx-auto w-full space-y-12 h-full flex items-center justify-center flex-col"
    >
      <div class="flex items-center flex-col text-center ">
        <p class="text-brand-primary text-lg md:text-2xl font-semibold mb-1">
          Hello! {{ user?.name + "," }} Welcome to Medusa
        </p>
        <span class="text-lg text-brand-gray font-light"
          >Please set a password</span
        >
      </div>

      <form class="w-full space-y-6" @submit.prevent="setPassword">
        <span
          class="text-sm font-normal text-red-400 inline-flex items-center"
          v-if="resetpassword.error.message"
          ><ExclamationCircleIcon class="w-4 h-4" />{{
            resetpassword.error.message
          }}</span
        >
        <div>
          <Input
            v-model:modelValue="resetpassword.password"
            label="New Password"
            desc="Must be atleast 6 characters"
            :inputClass="
                isSubmit && v$.resetpassword.password.$silentErrors.length > 0
                  ? 'error'
                  : ''
              "
            :type="showPassword ? 'text' : 'password'"
            placeholder="Enter your password"
          >
            <template #extra>
              <span
                @click="showPassword = !showPassword"
                class="absolute right-4 top-1/2 -mt-2.5 cursor-pointer text-theme-dark-shade hover:text-theme-dark"
              >
                <EyeIcon class="w-5 h-5" v-if="showPassword" />
                <EyeOffIcon class="w-5 h-5" v-else />
              </span>
            </template>
          </Input>
            <span
              class="text-sm text-theme-body font-normal pt-1.5 inline-block"
              v-if="
                !(
                  isSubmit && v$.resetpassword.password.$silentErrors.length > 0
                )
              "
              >Must be at least 8 characters</span
            >
            <span
              v-if="
                isSubmit && v$.resetpassword.password.$silentErrors.length > 0
              "
              class="text-sm font-light text-red-400 py-2"
            >
              <span
                v-if="
                  v$.resetpassword.password.$silentErrors[0].$validator ==
                    'required'
                "
                >Password is required.</span
              >
              <span
                v-else-if="
                  v$.resetpassword.password.$silentErrors[0].$validator ==
                    'minLength'
                "
                >Your password must be atleast 8 characters long.</span
              >
            </span>
        </div>
        <div>
          <Input
            v-model:modelValue="resetpassword.password_confirmation"
            label="Confirm Password"
            placeholder="Enter your password"
            :inputClass="
                isSubmit &&
                v$.resetpassword.password_confirmation.$silentErrors.length > 0
                  ? 'error'
                  : ''
              "
            :type="lookPassword ? 'text' : 'password'"
          >
            <template #extra>
              <span
                @click="lookPassword = !lookPassword"
                class="absolute right-4 top-1/2 -mt-2.5 cursor-pointer text-theme-dark-shade hover:text-theme-dark"
              >
                <EyeIcon class="w-5 h-5" v-if="lookPassword" />
                <EyeOffIcon class="w-5 h-5" v-else />
              </span>
            </template>
          </Input>
            <span
              v-if="
                isSubmit &&
                  v$.resetpassword.password_confirmation.$silentErrors.length >
                    0
              "
              class="text-sm font-light text-red-400 py-2"
            >
              <span
                v-if="
                  v$.resetpassword.password_confirmation.$silentErrors[0]
                    .$validator == 'required'
                "
                >Confirm password is required.</span
              >
              <span
                v-else-if="
                  v$.resetpassword.password_confirmation.$silentErrors[0]
                    .$validator == 'sameAsPassword'
                "
                >The password confirmation does not match.</span
              >
          </span>
        </div>
        <div>
          <Button
            icon="right-arrow"
            title="Set Password"
            type="submit"
            :loader="false"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/outline";
import { ref, onMounted, inject } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import Auth from "@/modules/Auth";
import { useRoute } from 'vue-router';
import { ExclamationCircleIcon } from "@heroicons/vue/outline";

export default {
  name: "SetPassword",
  components: {
    EyeIcon,
    EyeOffIcon,
    ExclamationCircleIcon
  },
  data() {
    return {
      v$: useVuelidate(),
      isSubmit: false
    };
  },
  validations() {
    return {
      resetpassword: {
        password: { required, minLength: minLength(8) },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.resetpassword.password),
        },
      },
    };
  },
  methods: {
    setPassword() {
      this.isSubmit = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        this.Passwordreset();
      }
    },
  },
  setup() {
    const showPassword = ref(false);
    const lookPassword = ref(false);
    const user = ref('');

    const axios = inject("axios");
    const route = useRoute();

    const { resetpassword, Passwordreset } = Auth();

    onMounted(() => {
      axios.api.get('/account/getUser/'+ route.path.split('/')[2])
        .then((res) => {
          if(res.data.data){
            user.value = res.data.data;
          }
      });
    });

    return {
      user,
      showPassword,
      lookPassword,
      Passwordreset,
      resetpassword,
    };
  },
};
</script>
<style></style>
